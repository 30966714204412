import {
  ChakraProvider,
  Box,
  Container,
  Grid,
  theme
} from "@chakra-ui/react"
import { KoitoContent } from "./components/KoitoContent"
import { Location } from "./components/Location"

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box bgGradient='linear(to-br, lavender, #FFFDD0)'>
      <Container maxW={[ 'sm', '6xl' ]}>
        <Grid minH="100vh">
          <Box />
          <KoitoContent />
        </Grid>

        {/* google maps directions */}
        <Location />
      </Container>
    </Box>
  </ChakraProvider>
)
